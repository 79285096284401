<template>
  <v-card
    v-if="
      Object.keys(selectedDatasource).length &&
      selectedDatasource.name &&
      selectedDatasource.datasourceId
    "
    class="mt-8 pa-4"
  >
    <h3 class="text-uppercase">{{ selectedDatasource.name }} datasets</h3>
    <GsDataTable
      ref="table"
      api=""
      :endpoint="computedAPIURL"
      appSettings="datasets"
      :headers="headers"
      :sort-by="['name']"
      :sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
    >
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="(actionId) => action_activate(actionId, item)"
        />
      </template>
      <template v-slot:item.sum="{ item }">
        <v-icon v-if="item.sum === 'true'" color="green"
          >mdi-checkbox-marked-outline</v-icon
        >
        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
      </template>
      <template v-slot:item.tags="{ item }">
        <ul v-if="item.tags && item.tags.length">
          <li v-for="(tag, index) in item.tags" :key="index">{{ tag }}</li>
        </ul>
        <div v-else>-</div>
      </template>
    </GsDataTable>
    <!-- add/edit tags options -->
    <v-dialog v-model="dialogTagsVisible" persistent max-width="600px">
      <v-card v-if="selectedDataset">
        <v-toolbar color="primary" dark
          >{{ $t("edit_tags") }} - {{ selectedDataset.name }}</v-toolbar
        >
        <GsDatasetTags
          :datasourceId="selectedDatasource.datasourceId"
          :datasetId="selectedDataset.datasetId"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="mb-3 close-button px-4"
            @click="dialogTagsVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
import GsDatasetTags from "@/components/GsDatasetTags";

export default {
  name: "GsDataSetsTable",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsDatasetTags,
  },
  props: {
    datasource: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    datasource: {
      deep: true,
      handler(val, oldVal) {
        this.selectedDatasource = val;
        if (Object.keys(oldVal).length) {
          this.$refs.table.goToFirstPage();
        }
      },
    },
  },
  data() {
    return {
      selectedDatasource: {},
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("name"),
          value: "name",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("func"),
          value: "func",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("sum"),
          value: "sum",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("tags"),
          value: "tags",
          width: 160,
          sortable: true,
        },
      ],
      dialogTagsVisible: false,
      selectedDataset: null,
      actions: ["edit_tags"],
    };
  },
  computed: {
    computedAPIURL() {
      return `/datasources/${this.selectedDatasource.datasourceId}/datasets`;
    },
  },
  methods: {
    async beforeCallApi(params) {
      params.url = this.computedAPIURL;
      return params;
    },
    edit_tags_click(item) {
      this.selectedDataset = item;
      this.dialogTagsVisible = true;
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "edit_tags":
          this.edit_tags_click(item);
          break;
      }
    },
  },
};
</script>
