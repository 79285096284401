<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>
        {{
          formData && formData.name
            ? $t("edit_datasource") + " - " + formData.name
            : $t("add_datasource")
        }}
      </span>
      <v-spacer />
      <v-icon @click="$emit('cancel')">mdi-close</v-icon>
    </v-toolbar>

    <v-form ref="form" class="pa-6">
      <v-row>
        <v-text-field
          :label="$t('name')"
          type="text"
          v-model="formData.name"
          :rules="formRules.required"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          :label="$t('url')"
          type="text"
          v-model="formData.url"
          :rules="formRules.required"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          :label="$t('dataset')"
          type="text"
          v-model="formData.dataset"
          :rules="formRules.required"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          :label="$t('query')"
          type="text"
          v-model="formData.query"
          :rules="formRules.required"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-select
          v-model="formData.authMethod"
          :items="authMethods"
          :label="$t('auth_method')"
          class="test-locator-authMethod"
          multiple
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
        >
          <template v-slot:item="{ item }">
            <span :label="item">{{ item }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <v-select
          v-model="formData.status"
          :items="statuses"
          :label="$t('status')"
          class="test-locator-status"
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
        >
          <template v-slot:item="{ item }">
            <span :label="item">{{ item }}</span>
          </template>
        </v-select>
      </v-row>
    </v-form>

    <!-- errors -->
    <div class="d-flex flex-row px-3" v-if="error">
      <span class="subtitle-1 mb-4 red--text">
        {{ $t("form_invalid_text") }}: {{ error }}
      </span>
    </div>

    <v-card-actions>
      <!-- <v-btn class="mr-2" color="default" @click="$emit('cancel')">
        {{ $t("close") }}
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const enums = require("@/app-data/datasource-enums.json");
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsDatasourceEditor",
  props: {},
  data() {
    return {
      formData: {
        name: null,
        url: null,
        dataset: null,
        query: null,
        authMethod: null,
        status: null,
      },
      formRules: {
        required: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredIfCreate: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredArray: [
          (value) =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true,
        ],
        optional: [(value) => true],
      },
      authMethods: enums.authMethods,
      statuses: enums.status,
      valid: true,
      error: null,
      loading: false,
    };
  },
  methods: {
    async create() {
      this.clearForm();
    },
    async edit(datasourceId) {
      this.clearForm();
      if (typeof datasourceId == "string") {
        this.formData = await this.getDatasource(datasourceId);
      } else {
        this.formData = JSON.parse(JSON.stringify(datasourceId));
      }
      // console.log(this.formData);
    },
    async getDatasource(id) {
      try {
        const result = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/datasources/${id}`,
          method: "GET",
        });
        return result.data;
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async save() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close"),
          },
        });
        return;
      }
      const url = this.formData.datasourceId
        ? `${process.env.VUE_APP_BFF_ORIGIN}/datasources/${this.formData.datasourceId}`
        : `${process.env.VUE_APP_BFF_ORIGIN}/datasources`;

      const method = this.formData.datasourceId ? "PATCH" : "POST";

      const data = {
        name: this.formData.name,
        url: this.formData.url,
        dataset: this.formData.dataset,
        query: this.formData.query,
        authMethod: this.formData.authMethod,
        status: this.formData.status,
      };

      try {
        this.loading = true;
        const result = await callAPI({ url, method, data });
        this.$emit("saved", result.data);
        this.clearForm();
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode === 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.$refs.form.resetValidation();
      this.valid = true;
      this.formData = {
        name: null,
        url: null,
        dataset: null,
        query: null,
        authMethod: null,
        status: null,
      };
      this.error = null;
    },
  },
};
</script>
