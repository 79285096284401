<template>
  <div class="ma-4" v-if="datasourceId && datasetId">
    <!-- abac -->
    <h3>{{ $t("templateTags.abac") }}</h3>
    <div v-if="actualTags.abac && actualTags.abac.length">
      <div
        :key="index"
        v-for="(tag, index) in actualTags.abac"
        class="d-flex mb-2"
      >
        <span class="mr-4">{{ tag }}</span>
        <v-icon small @click="deleteTag(tag)">mdi-delete</v-icon>
      </div>
    </div>
    <div v-else>
      {{ $t("no_result") }}
    </div>
    <div
      class="mt-2 d-flex align-center"
      v-if="tagCollection.abac && tagCollection.abac.length"
    >
      <v-select
        :items="forAbacDropdown"
        :label="$t('addNewTag')"
        v-model="selectedForAbac"
      ></v-select>
      <v-btn @click="addTag('abac')" class="ml-4">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <!-- group -->
    <!-- <h3 class="mt-4">{{ $t("templateTags.group") }}</h3> -->
    <!-- <div v-if="actualTags.group && actualTags.group.length">
      <div
        :key="index"
        v-for="(tag, index) in actualTags.group"
        class="d-flex mb-2"
      >
        <span class="mr-4">{{ tag }}</span>
        <v-icon small @click="deleteTag(tag)">mdi-delete</v-icon>
      </div>
    </div> -->
    <!-- <div v-else>
      {{ $t("no_result") }}
    </div> -->
    <!-- <div
      class="mt-2 d-flex align-center"
      v-if="tagCollection.group && tagCollection.group.length"
    >
      <v-select
        :items="forGroupDropdown"
        :label="$t('addNewTag')"
        v-model="selectedForGroup"
      ></v-select>
      <v-btn @click="addTag('group')" class="ml-4">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div> -->
  </div>
  <div class="ma-4" v-else>
    {{ $t("no_result") }}
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsDatasetTags",
  props: {
    datasourceId: {
      type: String,
      default: null,
    },
    datasetId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      actualTags: {},
      selectedForGroup: null,
      selectedForAbac: null,
    };
  },
  computed: {
    tagCollection() {
      return this.$store.state.tagCollection.template;
    },
    forAbacDropdown() {
      if (this.actualTags.abac) {
        return this.tagCollection.abac.filter(
          (item) => this.actualTags.abac.indexOf(item) === -1
        );
      }
      return this.tagCollection.abac;
    },
    forGroupDropdown() {
      if (this.actualTags.group) {
        return this.tagCollection.group.filter(
          (item) => this.actualTags.group.indexOf(item) === -1
        );
      }
      return this.tagCollection.group;
    },
  },
  watch: {
    datasetId: {
      immediate: true,
      handler(val) {
        if (val && this.datasourceId) {
          this.getTags("role:reportManager:template:");
        }
      },
    },
  },
  methods: {
    async getTags(prefix) {
      try {
        const { data } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/datasets/${this.datasetId}/tags`,
          method: "GET",
        });
        this.actualTags = {};
        data.forEach((item) => {
          const tagType = item.split(prefix).pop().split(":").shift();
          if (!this.actualTags[tagType]) {
            this.actualTags[tagType] = [];
          }
          this.actualTags[tagType].push(item);
        });
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async deleteTag(tag) {
      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/datasets/${this.datasetId}/tags/${tag}`,
          method: "DELETE",
        });
        await this.getTags("role:reportManager:template:");
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async addTag(type) {
      var tagToAdd = "";
      if (type === "abac") {
        tagToAdd = this.selectedForAbac;
      } else if (type === "group") {
        tagToAdd = this.selectedForGroup;
      }

      if (!tagToAdd) {
        return;
      }

      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/datasets/${this.datasetId}/tags`,
          method: "POST",
          data: {
            tag: tagToAdd,
          },
        });

        if (type === "abac") {
          this.selectedForAbac = null;
        } else if (type === "group") {
          this.selectedForGroup = null;
        }

        await this.getTags("role:reportManager:template:");
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
  },
};
</script>
