<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn dark color="primary" class="mb-2" @click="createDataSource">
        <v-icon small class="mr-2">mdi mdi-plus</v-icon>
        {{ $t("add") }}
      </v-btn>
    </v-row>
    <!-- filter panel -->
    <v-row> </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/datasources"
            appSetting="datasources"
            :headers="headers"
            :sort-by="['name']"
            :sort-desc="[false]"
            :beforeCallApi="beforeCallApi"
            @click:row="rowClicked"
          >
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>
            <template v-slot:item.name="{ item }">
              <v-img
                :src="downloadImg(item.name)"
                contain
                class="mr-3 comp-logo"
              />
              <strong class="comp-name">{{ item.name }}</strong>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark>{{
                $t(item.status.split(".").pop())
              }}</v-chip>
            </template>
            <!-- <template v-slot:item.datasourceId="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="showEditor(item.datasourceId)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi mdi-pencil</v-icon
                  >
                </template>
                <span>{{ $t("edit") }}</span>
              </v-tooltip>
            </template> -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} /
              {{ props.itemsLength }}
            </template>
          </GsDataTable>
        </v-card>
        <GsDataSetsTable :datasource="selectedDatasource" />
      </v-container>
    </v-row>
    <!-- add/edit datasource -->
    <v-dialog v-model="dialogEditorVisible" persistent max-width="600px">
      <GsDatasourceEditor
        ref="dataSourceEditor"
        @saved="modalSave"
        @cancel="modalCancel"
      />
    </v-dialog>
    <!-- test only -->
    <!-- <v-card
      class="mx-auto mt-8"
      max-width="400"
      tile
      style="height:200px; overflow-y:scroll;"
    >
      <v-list v-for="item in massData" :key="item.reportId">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ item.reportId }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card> -->
    <!-- / test only -->
  </v-container>
</template>

<script>
import GsDatasourceEditor from "@/components/GsDatasourceEditor";
import GsDataSetsTable from "@/components/GsDataSetsTable";
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const defaultImg = require("@/assets/scopes/default.png");

export default {
  name: "Datasources",
  components: {
    GsDatasourceEditor,
    GsDataTable,
    GsActionsMenu,
    GsDataSetsTable,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("name"),
          value: "name",
          width: 160,
          sortable: true,
        },
        {
          text: this.$t("url"),
          value: "url",
          width: 160,
          sortable: false,
        },
        {
          text: this.$t("dataset"),
          value: "dataset",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("query"),
          value: "query",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("auth_method"),
          value: "authMethod",
          width: 200,
          sortable: false,
        },
        {
          text: this.$t("status"),
          value: "status",
          width: 100,
          sortable: false,
          align: "center",
        },
      ],
      dialogEditorVisible: false,
      massData: [],
      actions: ["edit"],
      selectedDatasource: {},
    };
  },
  async mounted() {
    // get "template" tag collection
    this.getTagCollection("template", "role:reportManager:template:");
  },
  watch: {},
  methods: {
    downloadImg(fileName) {
      try {
        // console.log(fileName)
        return require("@/assets/scopes/" + fileName + ".png");
      } catch {
        return defaultImg;
      }
    },
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(this.$store.state.user.ownedBy)}`
      return params;
    },
    async createDataSource() {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.dataSourceEditor) {
          return;
        }
        await this.$refs.dataSourceEditor.create();
      });
    },
    edit_click(item) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.dataSourceEditor) {
          return;
        }
        await this.$refs.dataSourceEditor.edit(item.datasourceId);
      });
    },
    modalCancel() {
      this.dialogEditorVisible = false;
    },
    async modalSave() {
      this.dialogEditorVisible = false;
      this.$refs.table.refreshTable();
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
      }
    },
    rowClicked(item) {
      this.selectedDatasource = item;
    },
    async getTagCollection(entity, prefix) {
      if (!Object.keys(this.$store.state.tagCollection.template).length) {
        try {
          const { data } = await callAPI({
            url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/tag-collection/${entity}`,
            method: "GET",
          });
          const tagCollection = {};
          data.forEach((item) => {
            const tagType = item.split(prefix).pop().split(":").shift();
            if (!tagCollection[tagType]) {
              tagCollection[tagType] = [];
            }
            tagCollection[tagType].push(item);
          });
          this.$store.state.tagCollection.template = tagCollection;
        } catch (error) {
          this.errorSnackbar(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-logo {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.comp-name {
  position: relative;
  top: -8px;
}
</style>
